import React from 'react';

export default function TeddyLogo(props: LogoProps): React.ReactElement {
  return (
    <svg
      width="38"
      height="42"
      preserveAspectRatio="none"
      viewBox="0 0 38 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M 9.164 12.161 C 9.164 12.161 6.669 15.284 2.976 13.542 C 2.976 13.542 -1.265 11.393 1.989 6.504 C 1.989 6.504 4.483 3.036 7.489 5.123 C 7.489 5.123 10.97 7.319 10.058 10.479 L 9.164 12.161 V 12.161 Z"
        fill="#C69C6D"
      />
      <path
        d="M 24.708 9.216 C 24.708 9.216 28.24 11.087 30.934 8.018 C 30.934 8.018 33.967 4.356 29.035 1.169 C 29.035 1.169 25.366 -1.018 23.44 2.093 C 23.44 2.093 21.124 5.494 23.219 8.03 L 24.708 9.216 V 9.216 Z"
        fill="#C69C6D"
      />
      <path
        d="M 7.451 10.797 C 7.451 10.797 6.237 12.317 4.439 11.469 C 4.439 11.469 2.374 10.423 3.958 8.043 C 3.958 8.043 5.173 6.355 6.636 7.37 C 6.636 7.37 8.329 8.439 7.886 9.977 L 7.451 10.796 V 10.797 Z"
        fill="#F8EBC9"
      />
      <path
        d="M 25.955 7.13 C 25.955 7.13 27.674 8.04 28.985 6.547 C 28.985 6.547 30.462 4.765 28.061 3.213 C 28.061 3.213 26.274 2.149 25.337 3.664 C 25.337 3.664 24.21 5.319 25.23 6.553 L 25.955 7.131 V 7.13 Z"
        fill="#F8EBC9"
      />
      <path
        d="M 13.423 22.198 C 13.423 22.198 10.169 27.204 9.983 30.312 C 9.983 30.312 8.641 30.037 7.897 30.055 C 7.897 30.055 5.466 26.823 2.532 28.929 C 2.532 28.929 -0.342 30.966 1.256 35.137 C 1.256 35.137 3.195 40.605 8.969 40.256 C 8.969 40.256 10.277 40.151 11.145 39.492 C 11.145 39.492 9.793 39.686 14.822 39.028 C 14.822 39.028 19.501 38.915 25.005 39.019 C 26.651 39.051 28.115 39.664 30.681 39.636 C 30.681 39.636 35.537 41.268 37.684 34.518 C 37.684 34.518 38.744 31.328 35.797 29.024 C 35.797 29.024 33.675 27.6 31.314 30.319 C 31.314 30.319 29.865 30.321 29.257 30.497 C 29.257 30.497 27.836 25.224 26.549 23.083 L 13.423 22.198 H 13.423 Z"
        fill="#C69C6D"
      />
      <path
        d="M 14.734 27.104 L 13.913 34.672 C 13.913 34.672 19.001 37.486 26.129 34.31 L 24.532 25.963 C 24.532 25.963 18.941 27.819 14.734 27.103 V 27.104 Z"
        fill="#F8EBC9"
      />
      <path
        d="M 11.34 26.349 C 11.34 26.349 9.245 35.484 13.543 39.89 C 13.543 39.89 14.966 41.476 17.389 41.013 C 17.389 41.013 19.356 40.474 19.434 38.721 C 19.546 36.211 17.421 34.956 16.482 32.334 C 16.482 32.334 15.108 28.378 15.718 26.881 L 11.34 26.349 V 26.349 Z"
        fill="#C69C6D"
      />
      <path
        d="M 26.489 24.604 C 26.489 24.604 30.892 34.083 27.393 39.147 C 27.393 39.147 26.255 40.948 23.789 40.898 C 23.789 40.898 21.759 40.695 21.389 38.979 C 20.859 36.523 22.69 35.292 23.231 32.188 C 23.231 32.188 23.692 29.968 22.862 25.964 L 26.49 24.603 L 26.489 24.604 Z"
        fill="#C69C6D"
      />
      <path
        d="M 20.574 26.698 C 20.574 26.698 9.592 29.848 5.993 21.629 C 5.993 21.629 2.599 13.642 9.515 7.155 C 9.515 7.155 15.928 1.64 22.599 4.551 C 22.599 4.551 29.773 7.134 31.126 15.216 C 31.126 15.216 33.125 23.894 20.573 26.698 L 20.574 26.698 Z"
        fill="#C69C6D"
      />
      <path
        d="M 22.888 17.624 C 21.042 16.522 18.498 17.123 18.498 17.123 C 18.498 17.123 15.905 17.445 14.558 19.12 C 14.558 19.12 13.028 20.696 13.578 23.237 C 13.578 23.237 14.001 25.53 16.62 26.147 C 16.62 26.147 18.188 26.512 20.123 26.165 C 22.059 25.817 23.402 24.928 23.402 24.928 C 25.643 23.44 25.241 21.142 25.241 21.142 C 24.872 18.568 22.889 17.623 22.889 17.623 L 22.888 17.624 Z"
        fill="#F8EBC9"
      />
      <path
        d="M 11.905 20.226 C 12.907 20.226 13.719 19.414 13.719 18.412 C 13.719 17.41 12.907 16.598 11.905 16.598 C 10.903 16.598 10.091 17.41 10.091 18.412 C 10.091 19.414 10.903 20.226 11.905 20.226 Z"
        fill="#534741"
      />
      <path
        d="M 12.268 18.58 C 12.65 18.58 12.96 18.27 12.96 17.888 C 12.96 17.505 12.65 17.195 12.268 17.195 C 11.886 17.195 11.576 17.505 11.576 17.888 C 11.576 18.27 11.886 18.58 12.268 18.58 Z"
        fill="white"
      />
      <path
        d="M 24.806 17.697 C 25.808 17.697 26.62 16.885 26.62 15.883 C 26.62 14.881 25.808 14.069 24.806 14.069 C 23.804 14.069 22.992 14.881 22.992 15.883 C 22.992 16.885 23.804 17.697 24.806 17.697 Z"
        fill="#534741"
      />
      <path
        d="M 25.038 16.043 C 25.411 16.043 25.714 15.74 25.714 15.367 C 25.714 14.994 25.411 14.691 25.038 14.691 C 24.665 14.691 24.362 14.994 24.362 15.367 C 24.362 15.74 24.665 16.043 25.038 16.043 Z"
        fill="white"
      />
      <path
        d="M 20.521 19.649 C 20.167 18.768 19.13 18.922 18.855 18.98 C 18.575 19.021 17.55 19.234 17.522 20.184 C 17.522 20.184 17.484 20.877 18.067 21.323 C 18.067 21.323 18.48 21.69 19.248 21.589 V 21.592 C 19.272 21.588 19.295 21.583 19.318 21.579 C 19.34 21.576 19.363 21.572 19.387 21.568 V 21.565 C 20.059 21.409 20.385 20.942 20.385 20.942 C 20.789 20.33 20.521 19.65 20.521 19.65 V 19.649 Z"
        fill="#534741"
      />
      <path
        d="M 7.359 33.326 C 8.494 35.326 8.515 37.19 7.556 38.095 C 6.41 39.175 4.167 37.874 3.031 35.874 C 1.896 33.874 1.9 31.617 3.264 30.843 C 4.629 30.069 6.224 31.325 7.36 33.326 H 7.359 Z"
        fill="#B2875B"
      />
      <path
        d="M 31.435 33.544 C 30.474 35.633 30.612 37.493 31.645 38.312 C 32.879 39.292 35.004 37.805 35.965 35.715 C 36.926 33.625 36.73 31.377 35.305 30.722 C 33.88 30.066 32.397 31.454 31.436 33.544 H 31.435 Z"
        fill="#B2875B"
      />
      <path
        d="M 19.083 24.147 C 17.931 24.147 17.252 23.601 17.219 23.573 C 17.08 23.459 17.06 23.254 17.174 23.115 C 17.288 22.976 17.493 22.957 17.632 23.071 C 17.662 23.094 18.42 23.687 19.711 23.433 L 19.742 23.427 C 21.033 23.172 21.51 22.337 21.529 22.302 C 21.616 22.146 21.813 22.088 21.97 22.173 C 22.127 22.259 22.186 22.455 22.101 22.612 C 22.076 22.658 21.473 23.749 19.867 24.065 L 19.837 24.071 C 19.567 24.124 19.315 24.147 19.083 24.147 V 24.147 Z"
        fill="#534741"
      />
      <path
        d="M 19.694 23.912 C 19.54 23.912 19.404 23.802 19.374 23.645 L 18.868 20.896 C 18.836 20.72 18.953 20.55 19.129 20.517 C 19.307 20.485 19.476 20.602 19.508 20.778 L 20.014 23.527 C 20.047 23.704 19.93 23.874 19.753 23.906 C 19.733 23.909 19.713 23.912 19.694 23.912 Z"
        fill="#534741"
      />
    </svg>
  );
}

TeddyLogo.defaultProps = {};

interface LogoProps {
  className?: string;
}
