export const colorTable: string[] = [
  '#FF5135',
  '#AA3E19',
  '#A61D24',
  '#AA6215',
  '#1765AD',
  '#AA7714',
  '#AA9514',
  '#6F9412',
  '#3C8618',
  '#138585',
  '#263EA0',
  '#51258F',
  '#A02669',
];
