import React from 'react'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const SettingIcon = () => {
  return (
    <div className="border-2 rounded-md" style={{borderColor:"#4A4A4A", padding:"7px"}}>
            <SettingsOutlinedIcon/>
    </div>
  )
}

export default SettingIcon