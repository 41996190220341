import React from 'react'
import UpdateIcon from '@mui/icons-material/Update';

const TimeIcon = () => {
  return (
    <div className="border-2 rounded-md" style={{borderColor:"#4A4A4A", padding:"7px"}}>
            <UpdateIcon/>
    </div>
  )
}

export default TimeIcon